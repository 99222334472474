<template>
  <div class="user-balance-movements-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="8" :md="8" :sm="10" :xs="24">
        <h2 class="list-title">
          <font-awesome-icon
            class="anticon list-icon"
            :icon="['fas', 'money-bill-wave']"
          />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="16" :md="16" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="[0, 8]"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
            textAlign: 'right',
          }"
          class="row-reverse"
        >
          <a-col :xs="24" :md="16" :lg="18">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col :xs="24" :md="6" :lg="4" :style="{ textAlign: 'right' }">
            <a-button-group>
              <a-button type="primary" icon="plus" @click="openFilter">
                {{ labels.filters.filters }}
              </a-button>
              <a-button
                v-if="filtered"
                type="danger"
                icon="close"
                @click="handleResetFilter"
              />
            </a-button-group>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchUserBalanceMovements"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          v-bind="!isMobile && { scroll: { x: true } }"
          :paginationType="isMobile ? 'arrows' : 'numbers'"
          :customRow="handleCustomRow"
        />
      </a-col>
    </a-row>
    <FilterForm
      ref="filterForm"
      :labels="labels.filters"
      :visible="showFilter"
      :onClose="closeFilter"
      :onSearch="onFilter"
      :filters="filters"
    />
  </div>
</template>

<script>
import _ from "lodash";
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import accounting from "accounting";
import FilterForm from "./FilterForm.vue";
import { DATE_FORMAT, adminPageTitle } from "../../../utils/utils";
import { RouterLink } from "vue-router";
const initialState = {
  dateRange: [],
  type: [],
  number: "",
  user: [],
  reference: "",
};
export default {
  components: { StandardTable, FilterForm },
  name: "UserBalanceMovements",
  metaInfo: {
    title: adminPageTitle("User Balance Movements"),
  },
  data() {
    return {
      labels: labels.userbalancemovementList,
      search: "",
      showFilter: false,
      filters: _.cloneDeep(initialState),
      activeRow: null,
    };
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  methods: {
    ...mapActions("users", ["fetchUserBalanceMovements"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    openFilter() {
      this.showFilter = true;
    },
    closeFilter() {
      this.showFilter = false;
    },
    onFilter() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable, ...this.filters };
      Object.keys(result).forEach((key) => {
        switch (key) {
          case "user":
            if (!_.isEmpty(result[key])) result[key] = result[key].id;
            break;
          case "dateRange":
            if (!_.isEmpty(result[key]))
              result[key] = result[key].map(
                (item) => item && item.format(this.serverDateFormat)
              );
            break;
        }
      });
      this.$refs.standardTable.handleFilters(result);
      this.closeFilter();
    },
    handleResetFilter() {
      this.filters = _.cloneDeep(initialState);
      this.onFilter();
      this.closeFilter();
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format(DATE_FORMAT.MOMENT_DATE_TIME);
          },
        },
        {
          title: this.labels.table.columns.number,
          dataIndex: "number",
          key: "number",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.user,
          dataIndex: "user",
          key: "user",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return text?.id ? (
              <RouterLink
                target="_blank"
                to={{
                  name: "admin.users.edit",
                  params: { id: text?.id },
                }}
              >
                {text.email}
              </RouterLink>
            ) : (
              "-"
            );
          },
        },
        {
          title: this.labels.table.columns.reference,
          dataIndex: "reference",
          key: "reference",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text, record) => {
            return record.ticket?.id ? (
              <RouterLink
                target="_blank"
                to={{
                  name: "admin.orders.view",
                  params: { id: record.ticket?.id },
                }}
              >
                {text}
              </RouterLink>
            ) : record.transaction?.id ? (
              <RouterLink
                target="_blank"
                to={{
                  name: "admin.transactions.view",
                  params: { id: record.transaction?.id },
                }}
              >
                {text}
              </RouterLink>
            ) : (
              text
            );
          },
        },
        {
          title: this.labels.table.columns.description,
          dataIndex: "description",
          key: "description",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.debit,
          dataIndex: "amount",
          key: "debit-amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text, record) => {
            return record?.type === "DEBIT"
              ? accounting.formatNumber(text || 0, 2, ",", ".")
              : "";
          },
        },
        {
          title: this.labels.table.columns.credit,
          dataIndex: "amount",
          key: "credit-amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text, record) => {
            return record?.type === "CREDIT"
              ? accounting.formatNumber(text || 0, 2, ",", ".")
              : "";
          },
        },
        {
          title: this.labels.table.columns.balance,
          dataIndex: "new_balance",
          key: "new_balance",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.movements,
          dataIndex: "movements",
          key: "movements",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record) => {
            // Card item design
            const color = record?.type === "DEBIT" ? "#faad14" : "#52c41a";
            const type = record?.type
              ? this.labels.table.columns.type[record?.type?.toUpperCase()]
              : "-";
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-number">
                      <span>{record.number}</span>
                    </div>
                    <div class="list-item-head-content-user">
                      <span>{record?.user?.email}</span>
                    </div>
                    <div class="list-item-head-content-reference">
                      <span>
                        {record.reference}
                        <a-tooltip
                          placement="top"
                          title={record.description}
                          trigger="click"
                          class="list-item-head-content-reference-tooltip"
                        >
                          <a-icon
                            class="list-item-head-content-reference-tooltip-icon"
                            type="info-circle"
                            style={{ color: "#faad14" }}
                          />
                        </a-tooltip>
                      </span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-type">
                      <span>{type}</span>
                    </div>
                    <div class="list-item-head-footer-amount">
                      <span>
                        {accounting.formatNumber(record.amount, 2, ",", ".")}
                      </span>
                    </div>
                    <div class="list-item-head-footer-balance">
                      <span>
                        {accounting.formatNumber(
                          record.new_balance,
                          2,
                          ",",
                          "."
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          },
        },
      ].filter((column) => !column?.hidden);
    },
    filtered() {
      return !_.isEqual(this.filters, initialState);
    },
    serverDateFormat() {
      return DATE_FORMAT.SERVER_DATE;
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.user-balance-movements-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
    margin-left: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-view {
      color: color(primary);
    }
    .action-button-cancel {
      color: color(danger);
    }
  }
  @media screen and (max-width: $screen-mobile) {
    .row-reverse {
      flex-direction: column-reverse;
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 22%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 53%;
        overflow: hidden;
        &-number {
          span {
            font-size: 14px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-type {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
        &-reference {
          span {
            font-size: 14px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
          &-tooltip {
            margin-left: 6px;
            .anticon {
              font-size: 18px;
            }
          }
        }
        &-user {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        &-type {
          span {
            font-size: 12px;
            font-weight: 400;
          }
        }
        &-amount {
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
        &-balance {
          span {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
